<template>
    <MainHeader/>
    <div class="content" >
    <div id="facturation" class="container">
        <h1>Prise en charge des frais</h1>
        <hr>
        <p>
        Les prestations de soins sont dispensées sur prescription médicale. 
        Elles sont facturées sur une base horaire et sont prises en charge par 
        l'assurance maladie de base, sous réserve des déductions légales.
        <br><br>
        Les tarifs pour les prestations à charge de l’assurance maladie sont fixés 
        par le Conseil fédéral ; ils sont les mêmes dans toute la Suisse :
        </p>
        <div class="pricing-wrapper">
            <div>
                <h4>Evaluation et conseil</h4> 
                <span>76.90/heure</span>
            </div>
            <div>
                <h4>Examens et traitements </h4> 
                <span>63.00/heure</span>
            </div>
            <div>
                <h4>Soins de base</h4> 
                <span>52.60 /heure</span>
            </div>
        </div>
        <!-- <h3 class="note">
            Nos prestations sont prises en charge à 100% par l’assurance 
            maladie de base sans quote-part sous réserve d’une prescription 
            médicale
        </h3> -->
    </div>
</div>
<MainFooter/>
</template>

<script>

import MainHeader from '../global/MainHeader.vue';
import MainFooter from '../global/MainFooter.vue';

export default {
    name: 'FacturationMain',
    data() {
        return {
            //
        }
    },
    methods: {
        //
    },
    components: {
        MainHeader,
        MainFooter
    }
}
</script>

<style scoped>
    div#facturation {
    margin-top: 5vh;
    margin-bottom: 5vh;
}

div#facturation > h3 {
    text-align: center;
    color: var(--green);
}

div#facturation > h1 {
    text-align: center;
}

div#facturation hr{
    width:15vh;
    border: none;
    border-bottom: 2px solid rgba(119, 119, 119, 0.25);
    margin-bottom: 5vh;
}

div#facturation > div.pricing-wrapper {
    display: flex;
    column-gap: 2vh;
    padding: 5vh;
    margin-bottom: 2vh;
}

div#facturation > div.pricing-wrapper > div {
    background-color: var(--grey-green);
    color: var(--white);
    flex: 33.3%;
    padding: 3vh;
    border-radius: 25px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
    text-align: center;
    transform: translateY(0%);
    transition: 0.5s;
}

div#facturation > div.pricing-wrapper > div:hover {
    transform: translateY(-10%);
}

@media screen and (max-width: 768px) {
    div#facturation > div.pricing-wrapper {
        flex-direction: column;
        row-gap: 2vh;
    }
}
</style>